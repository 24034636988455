.brands .card-bg {
    background: none;
    background-color: #fafafa;
}

.brands .card-body {
    border-radius: 0px;
}

.brands .brands-card:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.brands .brands-card {
    transition: transform .2s;
}

.brands .card-bg:hover {
    background: none;
    background-color: #f3f0f0;
}
.footer-container {
    position: relative;
    background: url('../images/footer-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    height: 400px;
}

@media screen and (max-width: 1200px) {
    .footer-container {
        background-size: 200% 100%;
    }
}

.address {
    position: absolute;
    bottom: 0px;
    padding-left: 20px;
    padding-bottom: 20px;
}

.footer-container h4, .footer-container h5, .footer-container p, .footer-container a {
    color: #ffffff;
}

.footer-container h5 {
    margin-bottom: 25px;
    line-height: 1.3;
}

@media screen and (max-width: 460px) {
    .footer-container h4, .footer-container h5 {
        font-size: 1rem;
    }
}
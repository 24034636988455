:root {
    --primary: #1aac83;
    --error: #e7195a;
}

.addContainer {
    padding-top: 100px;
}

label, input, textarea {
    display: block;
  }
  input, textarea {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  form button {
    background: var(--primary);
    border: 0;
    color: #fff;
    padding: 10px;
    font-family: "Poppins";
    border-radius: 4px;
    cursor: pointer;
  }
  div.error {
    padding: 10px;
    background: #ffefef;
    border: 1px solid var(--error);
    color: var(--error);
    border-radius: 4px;
    margin: 20px 0;
  }

  div.success {
    padding: 10px;
    background: #e9fcf6;
    border: 1px solid var(--primary);
    color: var(--primary);
    border-radius: 4px;
    margin: 20px 0;
  }
.left-gradient {
  /* position: absolute; */
  background: url("../images/01.png");
  background-repeat: no-repeat;
  background-size: 370px 100%;
  background-position: left;
  min-height: 600px;
}

.sidebar-productList {
  padding-top: 30px;
  padding-left: 40px;
  background: white;
  min-height: 450px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 7%);
  position: sticky;
  top: 80px;
}
.sidenav-burgerMenu{
  position: sticky;
  top: 80px;
  margin-top: 10px;
  margin-left: 10px;
}
.sidebar-productList p {
  cursor: pointer;
}
.sidebar-productList p:hover {
  transform: translate(5px,0);
}
.sidebar-productList img {
  width: 90%;
}
.sticky--productList {
  position: fixed;
  top: 0;
  width: 100%;
}

.logo-bg {
  display: inline-block;
  background-color: #ffffffa8;
  border-radius: 15px;
  padding: 10px 15px 10px 15px;
  margin-left: 30px;
}

.logo-bg img {
  width: 75px;
  height: 91px;
}

.products .prod-list-card {
  transition: transform .2s;
}

.products .prod-list-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.breadcrumb a {
  text-decoration: none;
  padding-left: 0.3rem;
}

@media only screen and (min-width: 992px) {
  .breadcrumb {
    padding-left: 40px;
  }
}

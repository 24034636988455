.company-banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 10%;
    min-height: 600px;
    padding-top: 150px;
  }

.company-banner .intro {
  margin-right: 130px;
}

.company-banner .intro > p {
  background-color: #ffffffd8;
  border-radius: 15px;
  margin-bottom: 15px;
  
}

@media screen and (min-width: 767px) {
    /* .company-banner .intro {
      margin-top: 70px;
    } */

    .services {
      padding-top: 3rem !important;
    }
  }
  
  .company-banner p {
    color: #3551a3;
    text-align: justify;
    font-size: 1.1em;
    margin-bottom: 0px;
    font-weight: 600;
  }
  
  .company-banner h1 {
    /* position: absolute;
    bottom: 0px; */
    padding-bottom: 30px;
    padding-left: 30px;
    font-size: 100px;
    color: #ffffff;
    text-shadow: 2px 2px 4px #000000;
    /* margin: 0px; */
  }
  
  @media screen and (max-width: 767px) {
    .company-banner h1 {
      position: relative;
      padding: 15px 15px 15px 0px;
      font-size: 50px;
    }
  
    .left-gradient {
      background: none;
    }
  
    .logo-bg {
      margin-left: 0px !important;
    }
  
    .company-banner {
      padding-top: 70px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .company-banner .intro {
      margin-right: 0px;
    }
  }

  .visionTitle {
    --bs-gutter-x: 0;
    padding-left: 3%;
  }

  .visionTitle h2 {
    padding: 1rem;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(180deg, #083a51 88%, #7cc242 40%);
    text-align: center;
    margin-bottom: 0;
    border-top-left-radius: 180px 180px;
  }

  .missionTitle {
    --bs-gutter-x: 0;
    padding-right: 3%;
  }

  .missionTitle h2 {
    padding: 1rem;
    color: #ffffff;
    font-weight: bold;
    background: -webkit-linear-gradient(0deg, #083a51 88%, #7cc242 40%);
    text-align: center;
    margin-bottom: 0;
    border-top-right-radius: 180px 180px;
  }

  .about .intro {
    background-color: #ffffffd8;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-right: 150px;
  }

  .about p {
    color: #3551a3;
    text-align: justify;
    font-size: 1.1em;
    margin-bottom: 0px;
    font-weight: 600;
  }

  .vm {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border-radius: 20px;
    /* border: 2px solid #00b227; */
    height: 100%;
    background-color: #ffffffd8;
  }
  /* #183feb */
  .vm h2 {
    /* position: absolute; */
    /* top: -25px; */
    /* left: 20px; */
    height: 48px;
    /* padding-right: 16px; */
    padding-left: 16px;
    /* background: #ffffff; */
    font-size: 24px;
    font-weight: 600;
    color: #404040;
    /* border-radius: 100%; */
    /* border: 2px solid #00b227; */
    /* z-index: -1; */
  }

  .vmtext {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    text-align: center;
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 768px) {
    .mission {
      padding-top: 50px;
    }
  }

  /* @media only screen and (min-width: 992px) {
    .visionCont {
      max-width: 720px;
    }
  } */

  .mission {
    padding-top: 50px;
  }
  
  .missiondiv {
    padding: 0px 20px 20px 20px;
  }

  .vm .card-body {
    background-color: transparent;
  }

.product-details {
  background-color: #ffffffb7;
  border-radius: 15px;
  padding: 20px;
}

.product-details-wrapper{
  width: 100%;
  padding-top: 70px;
}

.product-details-wrapper a{
  text-decoration: none;
  padding-left: 0.3rem;
}

.product-details-wrapper h5{
  margin-top: 0.5rem;
}

.product-details-wrapper img{
  object-fit: contain;
  width: 100%;
  max-height: 500px;
}
.product-details-container{
   margin-top: 50px;
   margin-bottom: 5%;
}
.product-details-container p{
  line-height: 28px !important;
}
/* Product Description */
.product-description {
  border-bottom: 1px solid #43484D;
  margin-bottom: 20px;
}
.product-description span {
  font-size: 12px;
  color: #358ED7;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
.product-description h1 {
  font-weight: 300;
  font-size: 52px;
  color: #43484D;
  letter-spacing: -2px;
}
.product-description p {
  font-size: 16px;
  font-weight: 400;
  color: #43484D;
  line-height: 24px;
}

@media only screen and (min-width: 576px) {
  .wrapper {
    margin: 0 auto;
    width: 80%
  }
}

@media only screen and (max-width: 576px) {
  .wrapper {
    margin: 0 auto;
    width: 90%
  }
}

.videowrapper {
  position: relative;
  padding-top: 56.25%;
  height: 0px;
}

.videowrapper iframe {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  border: none;
}

/* Product Configuration */
.product-color span,
.cable-config span {
  font-size: 14px;
  font-weight: 400;
  color: #86939E;
  margin-bottom: 20px;
  display: inline-block;
}

/* Product Color */
.product-color {
  margin-bottom: 30px;
}

.color-choose div {
  display: inline-block;
}

.color-choose input[type="radio"] {
  display: none;
}

.color-choose input[type="radio"] + label span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

.color-choose input[type="radio"] + label span {
  border: 2px solid #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.33);
}

.color-choose input[type="radio"]#red + label span {
  background-color: #C91524;
}
.color-choose input[type="radio"]#blue + label span {
  background-color: #314780;
}
.color-choose input[type="radio"]#black + label span {
  background-color: #323232;
}

.color-choose input[type="radio"]:checked + label span {
/*   background-image: url(images/check-icn.svg);
 */  background-repeat: no-repeat;
  background-position: center;
}

/* Cable Configuration */
.cable-choose {
  margin-bottom: 20px;
}

.cable-choose button {
  border: 2px solid #E1E8EE;
  border-radius: 6px;
  padding: 13px 20px;
  font-size: 14px;
  color: #5E6977;
  background-color: #fff;
  cursor: pointer;
  transition: all .5s;
}

.cable-choose button:hover,
.cable-choose button:active,
.cable-choose button:focus {
  border: 2px solid #86939E;
  outline: none;
}

.cable-config {
  border-bottom: 1px solid #E1E8EE;
  margin-bottom: 20px;
}

.cable-config a {
  color: #358ED7;
  text-decoration: none;
  font-size: 12px;
  position: relative;
  margin: 10px 0;
  display: inline-block;
}
.cable-config a:before {
  content: "?";
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid rgba(53, 142, 215, 0.5);
  display: inline-block;
  text-align: center;
  line-height: 16px;
  opacity: 0.5;
  margin-right: 5px;
}

/* Product Price */
.product-price {
  display: flex;
  align-items: center;
}

.product-price span {
  font-size: 26px;
  font-weight: 300;
  color: #43474D;
  margin-right: 20px;
}

.cart-btn {
  display: inline-block;
  background-color: #7DC855;
  border-radius: 6px;
  font-size: 16px;
  color: #FFFFFF;
  text-decoration: none;
  padding: 12px 30px;
  transition: all .5s;
}
.cart-btn:hover {
  background-color: #64af3d;
}

/* Responsive */
@media (max-width: 940px) {
  .container {
    flex-direction: column;
/*     margin-top: 60px;
 */  }

  /* .left-column,
  .right-column {
    width: 100%;
  } */

  .left-column img {
    width: 300px;
   /*  right: 0; */
    /* top: -65px; */
    left: initial;
  }
}

 @media (max-width: 535px) {
  .left-column img {
    width: 80%;
    top: -85px;
  }
  .right-column{
    float: none;
    width:100%;
  }

} 

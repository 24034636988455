*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{

  background: url('../vendors/images/04.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
